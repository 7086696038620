import "./src/index.scss";

/**
 * Initialise Tealium
 */
const initTealium = () => {
  if (!document) return; //what is this for? cypress, maybe

  // Work out what environment we are running in (client-side)
  const tealiumEnv = (() => {
    switch (document.location.host.split(".")[1]) {
      case undefined: // Localhost
      case "dev":
      case "uat":
      case "pre":
        return "dev";
      default:
        return "prod";
    }
  })();

  let a = `//tags.tiqcdn.com/utag/${process.env.GATSBY_TEALIUM_TAG}/${tealiumEnv}/utag.js`,
    b = document,
    c = "script",
    d = b.createElement(c);
  d.src = a;
  d.type = "text/java" + c;
  d.async = true;
  a = b.getElementsByTagName(c)[0];
  a.parentNode.insertBefore(d, a);
};

const initOneTrust = () => {
  if (!document || typeof window?._env_?.ONE_TRUST_ID === "undefined") return;

  const script = document.createElement("script");
  script.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
  script.type = "text/javascript";
  script.async = true;
  script.setAttribute("data-domain-script", window._env_.ONE_TRUST_ID);
  document.body.appendChild(script);

  const scriptFn = document.createElement("script");
  scriptFn.type = "text/javascript";
  scriptFn.innerHTML = 'function OptanonWrapper() { }';
  document.body.appendChild(scriptFn);
}

/**
 * Update Tealium/GA with new location
 * @param location
 */
const updateTealium = location => {
  if (typeof window.utag !== "undefined") {
    window.utag.view({ page_name: location.pathname });

    //Bing Pageview
    window.uetq = window.uetq || [];
    window.uetq.push("event", "page_view", { page_path: window.location.href });
  }
};

/**
 * Initialise Tealium UTAG data layer
 */
const initDataLayer = () => {
  if (typeof window !== "undefined") {
    window.utag_data = {
      page_type: "BSM Site",
      user_type: "Guest",
      full_url: window.location.href,
      page_url: window.location.pathname,
    };
  }
};

/**
 * Called when the Gatsby browser runtime first starts.
 */
export const onClientEntry = async () => {
  console.log("App Version:", process.env.GATSBY_APP_VERSION);

  //for cypress
  if (typeof window._env_ === "undefined") require("./env");

  initDataLayer();
  initTealium();
  initOneTrust();
  initDataLayer();
};

/**
 * Called when the user changes routes
 *
 * @param location
 */
export const onRouteUpdate = ({ location }) => {
  updateTealium(location);

  //Update UTAG data layer
  if (typeof window !== "undefined" && window.utag_data) {
    window.utag_data.full_url = window.location.href;
    window.utag_data.page_url = window.location.pathname;
  }
};
