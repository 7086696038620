// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-archive-jsx": () => import("../src/templates/blog-archive.jsx" /* webpackChunkName: "component---src-templates-blog-archive-jsx" */),
  "component---src-templates-template-jsx": () => import("../src/templates/template.jsx" /* webpackChunkName: "component---src-templates-template-jsx" */),
  "component---src-templates-locations-archive-jsx": () => import("../src/templates/locations-archive.jsx" /* webpackChunkName: "component---src-templates-locations-archive-jsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-jsx": () => import("../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-thanks-jsx": () => import("../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-pages-instructor-not-found-jsx": () => import("../src/pages/instructor-not-found.jsx" /* webpackChunkName: "component---src-pages-instructor-not-found-jsx" */)
}

