module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Titillium Web:400,400i,600&display=swap"]},"typekit":{"id":"cam1cfl"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b5486fbbfc0142c2859d201ed2ff082a@sentry.io/1826718","environment":"production","release":"ds-site-bsm@1.81.0-RC.13","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
